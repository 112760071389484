/* eslint-disable jsx-a11y/alt-text */
import { useState, useContext } from "react";
import CrakitIcon from "./../assets/crakkit.svg";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { menuItems, AppContext } from "./AppData";

export default function Home() {
    const [showMenu, setShowMenu] = useState(false);

    const { handleContactUsModalVisiblity } = useContext(AppContext);

    function handleScroll(id) {
        if (id === "contact-us") {
            handleContactUsModalVisiblity(true);
            return;
        }
        setShowMenu(false);
        const elem = document.getElementById(id);
        if (elem) {
            elem.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest"
            });
        }
    }
    return (
        <header className="header-height w-full sticky top-0 bg-white z-[100] border-b">
            <div className="content-width h-full flex items-center justify-between py-4">
                <div className="flex items-center justify-center flex-shrink-0">
                    <img src={CrakitIcon} className="w-8 h-8" />
                    <span className="text-3xl font-bold">Crakkit</span>
                </div>
                <div className="md:hidden text-xl">
                    {showMenu ? (
                        <CloseOutlined
                            key="close"
                            onClick={() => setShowMenu(false)}
                        />
                    ) : (
                        <MenuOutlined
                            key="menu"
                            onClick={() => setShowMenu(true)}
                        />
                    )}
                </div>
                <div
                    className="hidden absolute shadow-md md:shadow-none md:static w-full flex-col md:flex-row left-0 py-5 md:py-0 top-15 md:flex justify-end items-center gap-5 md:gap-10 font-normal text-xl bg-white"
                    style={showMenu ? { display: "flex" } : {}}
                >
                    {menuItems.map((menu) => (
                        <div
                            className="cursor-pointer"
                            onClick={() => handleScroll(menu.key)}
                            key={menu.key}
                        >
                            {menu.title}
                        </div>
                    ))}
                </div>
            </div>
        </header>
    );
}
