/* eslint-disable jsx-a11y/alt-text */
import { menuItems, extraMenuItems } from "./../components/AppData";
import CrakitIcon from "./../assets/crakkit.svg";
const menuData = [
    ...menuItems.filter(({ key }) => key !== "contacts"),
    ...extraMenuItems
];

export default function Footer() {
    function handleScroll(id) {
        const elem = document.getElementById(id);
        if (elem) {
            elem.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest"
            });
        }
    }

    return (
        <section
            className="mt-10 flex flex-col justify-center items-center bg-slate-900 text-white"
            id="contacts"
        >
            <div className="flex flex-row justify-between items-end w-3/4 mt-10">
                <div className="flex flex-col gap-1">
                    <div className="text-lg mb-2 font-semibold">Contact Us</div>
                    <div>Crakkit</div>
                    <div>Bellandur, Bangalore</div>
                    <div>support@crakkit.com</div>
                    <div>+91 91599 24373, +91 97873 77348</div>
                </div>
                <div className="hidden md:flex flex-col items-end">
                    <div className="text-lg mb-2 font-semibold">Crakkit</div>
                    {menuData.map((menu) => (
                        <div
                            className="cursor-pointer"
                            onClick={() => handleScroll(menu.key)}
                            key={menu.key}
                        >
                            {menu.title}
                        </div>
                    ))}
                </div>
            </div>

            <div className="mx-10 md:mx-auto w-3/4 border-t border-gray-400 mt-10 py-10 flex flex-col items-center justify-center ">
                <div className="flex items-center justify-center flex-shrink-0">
                    <img src={CrakitIcon} className="w-8 h-8" />
                    <span className="text-3xl font-bold">Crakkit</span>
                </div>
                <div className="mt-5 text-center">
                    © 2024 Crakkit. All Rights Reserved.
                </div>
            </div>
        </section>
    );
}
