import { createContext } from "react";
export const AppContext = createContext(null);
export const menuItems = [
    {
        key: "courses",
        title: "Courses"
    },
    {
        key: "mentors",
        title: "Mentors"
    },
    {
        key: "about",
        title: "About"
    },
    {
        key: "contact-us",
        title: "Contact Us"
    }
];
export const extraMenuItems = [
    {
        key: "feedbacks",
        title: "Reviews"
    },
    {
        key: "faqs",
        title: "FAQs"
    }
];
