/* eslint-disable jsx-a11y/alt-text */
import GirlThumbsUpIllustration from "./../assets/girl-thumbsup.png";
import StudentRaisingHand from "./../assets/student-raising-hand.png";
import RaiseUp from "./../assets/raise_up.svg";
import { SpeekIcon, BreifCaseIcon, IdeaIcon } from "../components/Icons";
import { Carousel, Button } from "antd";
import About from "./About";
import Courses from "./Courses";
import Instructors from "./Instructors";
import Students from "./Students";
import FAQ from "./FAQ";
import Footer from "./Footer";
import {
    CodeOutlined,
    LaptopOutlined,
    IdcardOutlined
} from "@ant-design/icons";
import { AppContext } from "./../components/AppData";
import { useContext } from "react";

function ImageSection1({ isMobileView }) {
    return (
        <div
            className={`${
                isMobileView
                    ? "md:hidden my-5 w-full flex justify-center"
                    : "hidden md:block mr-30"
            } relative`}
        >
            <div className="min-w-60 h-60 md:min-w-100 md:h-100 rounded-full relative overflow-hidden bg-green-2">
                <img src={GirlThumbsUpIllustration} className="absolute" />
            </div>
            <div className="absolute flex bg-white p-2 md:p-4 rounded-lg items-center justify-center bottom-0 right-0 md:bottom-auto md:right-auto md:top-30 md:-left-40">
                <div className="rounded bg-green-2 p-1">
                    {" "}
                    <LaptopOutlined className="text-4xl text-gray-50" />
                </div>
                <div className="flex flex-col ml-2 md:ml-3 font-semibold text-sm md:text-2xl">
                    <span className="text-black">4+</span>
                    <span className="mt-1 text-gray-500">Coding Courses</span>
                </div>
            </div>
            <div className="absolute flex bg-white p-2 md:p-4 rounded-lg items-center justify-center top-20 -right-3 md:top-0 md:-right-10">
                <div className="rounded bg-green-2 p-1 flex">
                    {" "}
                    <IdcardOutlined className="text-2xl text-gray-50" />
                </div>

                <div className="flex flex-col ml-2 md:ml-3 font-semibold text-sm md:text-lg">
                    <span className="mt-1 text-gray-500">Tutors</span>
                    <span className="text-black">5+</span>
                </div>
            </div>
            <div className="absolute flex flex-col bg-white p-2 md:p-4 rounded-lg items-center justify-center top-10 -left-3 md:top-auto md:left-auto md:right-0 md:bottom-0">
                <CodeOutlined className="text-4xl text-green-2" />
                <div className="flex flex-col mt-2 md:mt-3 font-semibold items-center justify-center">
                    <span className="text-black text-xl">5+</span>
                    <span className="mt-1 text-gray-500 text-sm">
                        Programming Languages
                    </span>
                </div>
            </div>
        </div>
    );
}

export default function Home() {
    const { handleContactUsModalVisiblity } = useContext(AppContext);
    return (
        <main>
            <Carousel>
                <section className="content-h bg-green-1">
                    <section className="content-width h-full flex justify-start items-center relative">
                        <div className="flex justify-center items-start flex-col">
                            <ImageSection1 isMobileView />
                            <span className="font-bold text-3xl md:text-6xl">
                                <div>
                                    Up Your{" "}
                                    <span className="text-white">Skills</span>
                                </div>
                                <div className="md:mt-3">
                                    To{" "}
                                    <span className="text-white">Advance</span>{" "}
                                    Your
                                </div>
                                <div className="md:mt-3">
                                    <span className="text-white">Career</span>{" "}
                                    Path
                                </div>
                            </span>
                            <div className="font-medium text-xl mt-5 md:mt-10 text-white md:w-1/2">
                                Unlock your coding potential and stay ahead in
                                the fast-evolving tech world with Crakkit.
                            </div>
                            <div className="flex justify-center items-center mt-5 md:mt-10">
                                <Button
                                    type="primary"
                                    shape="round"
                                    size="large"
                                    onClick={() =>
                                        handleContactUsModalVisiblity(true)
                                    }
                                >
                                    Join Now
                                </Button>
                            </div>
                            <div className="hidden md:flex flex-col md:flex-row justify-center items-start md:items-center mt-5 md:mt-10 text-gray-200 text-base md:text-lg">
                                <div className="flex items-center">
                                    <SpeekIcon className="w-5 h-5 mr-2" />
                                    <span>Cracking Interview</span>
                                </div>
                                <div className="flex items-center mt-4 md:mt-0 md:ml-6">
                                    <BreifCaseIcon className="w-5 h-5 mr-2" />
                                    <span>Career-Oriented</span>
                                </div>
                                <div className="flex items-center mt-4 md:mt-0 md:ml-6">
                                    <IdeaIcon className="w-5 h-5 mr-2" />
                                    <span>Problem Solving</span>
                                </div>
                            </div>
                        </div>
                        <ImageSection1 />
                    </section>
                </section>
                <section className="content-h bg-gradiant-1 rounded-br-[150px]">
                    <section className="content-width h-full flex justify-start items-center relative">
                        <div className="flex justify-center items-start flex-col">
                            <div className="w-full h-full flex justify-center mb-10 md:hidden">
                                <img
                                    src={StudentRaisingHand}
                                    className="max-w-[75%]"
                                />
                            </div>
                            <span className="font-bold text-4xl md:text-6xl text-white">
                                <div className="block">Upgrade Your Skills</div>
                                <div className="mt-2 md:mt-3">
                                    For Better Future
                                </div>
                            </span>
                            <div className="font-medium text-2xl mt-5 md:mt-10 text-white md:w-2/3">
                                Code smarter, not harder—with Crakkit, the
                                future of tech education is in your hands!
                                Future-proof your career with Crakkit and let AI
                                guide your coding journey
                            </div>
                            <div className="flex justify-center items-center mt-5 md:mt-10">
                                <Button
                                    type="primary"
                                    shape="round"
                                    size="large"
                                    onClick={() =>
                                        handleContactUsModalVisiblity(true)
                                    }
                                >
                                    Join Now
                                </Button>
                            </div>
                        </div>
                        <div className="w-3/4 hidden md:block">
                            <img src={StudentRaisingHand} className="h-full" />
                        </div>
                    </section>
                </section>
                {/* <div className="content-h ">
                    <section className="content-width h-full flex flex-row justify-center items-center">
                        <div className="flex flex-col justify-center items-start mt-5 text-black w-3/4 h-full">
                            <div className="flex items-center">
                                <SpeekIcon className="w-5 h-5 mr-2" />
                                <span className="text-4xl">
                                    Cracking Interview
                                </span>
                            </div>
                            <div className="flex items-center mt-4 md:mt-0 md:ml-6">
                                <BreifCaseIcon className="w-5 h-5 mr-2" />
                                <span className="text-4xl">
                                    Career-Oriented
                                </span>
                            </div>
                            <div className="flex items-center mt-4 md:mt-0 md:ml-6">
                                <IdeaIcon className="w-5 h-5 mr-2" />
                                <span className="text-4xl">
                                    Problem Solving
                                </span>
                            </div>
                        </div>
                        <div className="h-full hidden md:block">
                            <img src={RaiseUp} className="h-full" />
                        </div>
                    </section>
                </div> */}
            </Carousel>
            <About />
            <Courses />
            <Instructors />
            <Students />
            <FAQ />
            <Footer />
        </main>
    );
}
