import { Modal } from "antd";
import "./ContactUs.css";
import { useState, useContext } from "react";
import { AppContext } from "./../components/AppData";

function ContactUs({ visible, onClose }) {
    const [loading, setLoading] = useState();
    const { messageApi } = useContext(AppContext);
    const hanldeFormSubmit = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);
            await window.handleContactUsFormSubmit();
            onClose();
            messageApi.open({
                type: "success",
                content:
                    "Thank you for your response. We will get in touch with you soon."
            });
        } catch (e) {
            console.error(e);
            messageApi.open({
                type: "error",
                content: "An error occured!, Please try after sometimes."
            });
        } finally {
            setLoading(false);
        }
    };
    return (
        <Modal
            title={
                <div className="text-xl font-medium">Ask for a callback</div>
            }
            className="contact-us-form-modal"
            open={visible}
            footer={null}
            width={600}
            onCancel={onClose}
            closable
        >
            <section>
                <div
                    id="crmWebToEntityForm"
                    className="zcwf_lblLeft crmWebToEntityForm"
                    style={{ backgroundColor: "white", color: "#333333" }}
                >
                    <form
                        id="webform811870000000338856"
                        action="https://crm.zoho.in/crm/WebToLeadForm"
                        name="WebToLeads811870000000338856"
                        method="POST"
                        onSubmit={hanldeFormSubmit}
                        acceptCharset="UTF-8"
                        loading={loading}
                    >
                        <input
                            type="text"
                            style={{ display: "none" }}
                            name="xnQsjsdp"
                            defaultValue="52c6101d0e65cb73c763834501963daed8d7a0fc8fd331124eb4f194edd62352"
                        />
                        <input
                            type="hidden"
                            name="zc_gad"
                            id="zc_gad"
                            defaultValue=""
                        />
                        <input
                            type="text"
                            style={{ display: "none" }}
                            name="xmIwtLD"
                            defaultValue="33071e0d5bc45ccdab91d251e3033d13e07dba5584e7177bab6a16da86231a025fdf131c81e5d65e9f66a23cb67b2127"
                        />
                        <input
                            type="text"
                            style={{ display: "none" }}
                            name="actionType"
                            defaultValue="TGVhZHM="
                        />
                        <input
                            type="text"
                            style={{ display: "none" }}
                            name="returnURL"
                            defaultValue="null"
                        />
                        {/* Do not remove this code. */}
                        <div className="text-2xl font-semibold my-7">
                            Could you provide us with a few details?
                        </div>
                        <div className="flex flex-col md:flex-row justify-start items-start md:items-center mb-7 md:gap-5">
                            <div className="zcwf_row flex-grow  w-full">
                                <div
                                    className="zcwf_col_lab"
                                    style={{
                                        fontSize: 14,
                                        fontFamily: "Arial"
                                    }}
                                >
                                    <label htmlFor="First_Name">
                                        First Name
                                        <span className="text-red-600 ml-1 text-xl">
                                            *
                                        </span>
                                    </label>
                                </div>
                                <div className="zcwf_col_fld">
                                    <input
                                        type="text"
                                        id="First_Name"
                                        aria-required="true"
                                        aria-label="First Name"
                                        name="First Name"
                                        aria-valuemax={40}
                                        maxLength={40}
                                    />
                                    <div className="zcwf_col_help" />
                                </div>
                            </div>
                            <div className="zcwf_row flex-grow mt-7 w-full md:mt-0">
                                <div
                                    className="zcwf_col_lab"
                                    style={{
                                        fontSize: 14,
                                        fontFamily: "Arial"
                                    }}
                                >
                                    <label htmlFor="Last_Name">
                                        Last Name
                                        <span className="text-red-600 ml-1 text-xl">
                                            *
                                        </span>
                                    </label>
                                </div>
                                <div className="zcwf_col_fld">
                                    <input
                                        type="text"
                                        id="Last_Name"
                                        aria-required="true"
                                        aria-label="Last Name"
                                        name="Last Name"
                                        aria-valuemax={80}
                                        maxLength={80}
                                    />
                                    <div className="zcwf_col_help" />
                                </div>
                            </div>
                        </div>
                        <div className="zcwf_row mb-7">
                            <div
                                className="zcwf_col_lab"
                                style={{ fontSize: 14, fontFamily: "Arial" }}
                            >
                                <label htmlFor="Phone">
                                    Phone
                                    <span className="text-red-600 ml-1 text-xl">
                                        *
                                    </span>
                                </label>
                            </div>
                            <div className="zcwf_col_fld phone-col-fld">
                                <div className="flex flex-row justify-start phone-fld items-center pr-1">
                                    <span className="flex-shrink-0 py-2 text-slate-400">
                                        +91
                                    </span>
                                    <input
                                        className="phone grow h-full px-2"
                                        type="text"
                                        id="Phone"
                                        aria-required="true"
                                        aria-label="Phone"
                                        name="Phone"
                                        aria-valuemax={30}
                                        maxLength={30}
                                    />
                                </div>
                                <div className="zcwf_col_help" />
                            </div>
                        </div>
                        <div className="zcwf_row mb-10">
                            <div
                                className="zcwf_col_lab"
                                style={{ fontSize: 14, fontFamily: "Arial" }}
                            >
                                <label htmlFor="Email">Email</label>
                            </div>
                            <div className="zcwf_col_fld">
                                <input
                                    type="text"
                                    ftype="email"
                                    autoComplete="false"
                                    id="Email"
                                    aria-required="false"
                                    aria-label="Email"
                                    name="Email"
                                    aria-valuemax={100}
                                    crmlabel=""
                                    maxLength={100}
                                />
                                <div className="zcwf_col_help" />
                            </div>
                        </div>
                        <div className="zcwf_row mb-0">
                            <div className="zcwf_col_lab" />
                            <div className="zcwf_col_fld">
                                <input
                                    type="submit"
                                    id="formsubmit"
                                    role="button"
                                    className="formsubmit zcwf_button"
                                    defaultValue="Submit"
                                    aria-label="Submit"
                                    title="Submit"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </Modal>
    );
}

export function useContactUs() {
    const [visible, setVisible] = useState(false);
    return {
        contactUsModal: (
            <ContactUs visible={visible} onClose={() => setVisible(false)} />
        ),
        handleContactUsModalVisiblity: setVisible
    };
}
